import React from 'react';
import { makeAircraft, makeCallsign } from '../../components/Listing/utils';
import AirlineLogo from '../../components/AirlineLogo';
import AirportName from '../../components/AirportName';
import moment from 'moment';
import FlightState from '../../components/FlightState';
import { DASH } from '../../constants';
import SecondaryItem from '../../components/Listing/SecondaryItem';

export const makeAirlineTitle = (airline) => {
  return airline.name;
};

export const makeAirlineSubtitle = (airline) => {
  return `${airline.icao}${airline.iata && ` / ${airline.iata}`}`;
};

export const makeColumns = () => ({
  callsign: {
    name: 'Flight Number',
    width: '90px',
    order: 1,
  },
  aircraft: {
    name: 'Aircraft',
    width: '80px',
    order: 2,
    hidden: 'xs',
  },
  flight: {
    name: 'Flight',
    order: 3,
  },
  departureTime: {
    name: 'Dep. Time',
    width: '65px',
    order: 5,
  },
  enRouteTime: {
    name: 'Time Enroute',
    width: '65px',
    order: 6,
    hidden: 'sm',
  },
  arrivalTime: {
    name: 'Arrival Time',
    width: '65px',
    order: 7,
  },
  status: {
    name: 'Status',
    width: {
      default: '170px',
      sm: '80px',
    },
    order: 8,
  },
});

export const makeRows = (data, classes) => data.map((item) => ({
  callsign: makeCallsign(item),
  aircraft: makeAircraft(item.aircraft),
  airline: <AirlineLogo airline={item.airline} callsign={item.callsign} />,
  enRouteTime: (
    <SecondaryItem>
      { moment.utc(item.en_route_time * 1000).format('HH:mm') }
    </SecondaryItem>
  ),
  status: <FlightState id={item.state.id} title={item.state.title} />,
  departureTime: moment.unix(item.departure_at).format('HH:mm'),
  arrivalTime: moment.unix(item.arrival_at).format('HH:mm'),
  flight: (
    <>
      <AirportName data={item.departure} withFlag flagPlacement='left' />
      <span className={classes.flightDivider}>{DASH}</span>
      <AirportName data={item.destination} withFlag flagPlacement='right' />
    </>
  ),
}));