import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Airplane as AirplaneIcon,
} from 'mdi-material-ui';
import {
  Board,
  BoardHeading
} from 'components/Board';
import { useStores } from '../../stores';
import Listing from 'components/Listing';
import { makeColumns } from './utils';
import { makeArrivalsRows } from '../AirportPage/utils';
import { useStyles } from '../AirportPage/styles';

const POLLING_INTERVAL = 30000;

function AirlinePage(props) {
  const {
    appStore,
    airlineStore,
    searchStore,
  } = useStores();

  const classes = useStyles();
  let pollingIntervalId = null;

  const handleSearch = (value) => {
    console.debug('Setting airline from search');
    airlineStore.selectAirline(value);
    props.history.push(`/airline/${value}`);
    localStorage.setItem('lastAirline', value);
  };

  useEffect(() => {
    searchStore.setParams({
      label: 'Enter ICAO-code',
      length: 3,
    });
    searchStore.setHandler(handleSearch);
  }, []);

  useEffect(
    () => appStore.toggleLoader(airlineStore.isLoading),
    [airlineStore.isLoading]
  );

  useEffect(() => {
    (() => {
      if (!airlineStore.selected) {
        return;
      }

      if (searchStore.value !== airlineStore.selected) {
        searchStore.setValue(airlineStore.selected);
      }

      console.debug('Loading data for airline', airlineStore.selected);
      airlineStore.fetchAirline();
      airlineStore.fetchFlights();

      if (!pollingIntervalId) {
        console.debug('Starting polling interval');
        pollingIntervalId = setInterval(
          () => airlineStore.fetchFlights(),
          POLLING_INTERVAL
        );
      }
    })();

    return () => {
      if (pollingIntervalId) {
        console.debug('Clearing polling interval');
        clearInterval(pollingIntervalId);
      }
    };
  }, [airlineStore.selected]);

  return (
    <>
      <Board>
        <BoardHeading
          title={airlineStore.airline.name}
          subtitle='Flights'
          icon={AirplaneIcon}
          timestamp={airlineStore.updatedAt}
        />

        <Listing
          columns={makeColumns()}
          rows={makeArrivalsRows(airlineStore.flights, classes)}
        />
      </Board>
    </>
  );
}

export default observer(AirlinePage);