import React from 'react';
import classNames from 'classnames';
import { useTableStyles } from './styles';
import { sortBy } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const MINIMUM_ROWS_AMOUNT = 10;

const getColumns = (columns, mq) => {
  for (const key in columns) {
    columns[key]['key'] = key;
  }

  const values = Object.values(columns ?? {})
    .filter(column => {
      if (mq.sm && column.hidden === 'sm') {
        return false;
      }
      return !(mq.xs && column.hidden === 'xs');
    });

  return sortBy(values, ['order']);
};

const getColumnKeys = (columns) => {
  return columns.map(column => column.key);
};

const getColumnWidth = (column, mq) => {
  if (typeof column.width === 'object') {
    if (mq.xs && column.width.xs) {
      return column.width.xs;
    }
    if (mq.sm && column.width.sm) {
      return column.width.sm;
    }
    return column.width.default;
  }

  return column.width || 'auto';
};

function Table(props) {
  const {
    //columns: allColumns,
    rows,
  } = props;

  const classes = useTableStyles();

  const mq = {
    sm: useMediaQuery(theme => theme.breakpoints.down('sm')),
    xs: useMediaQuery(theme => theme.breakpoints.down('xs')),
  };

  const columns = getColumns(props.columns, mq);
  const columnKeys = getColumnKeys(columns);

  const emptyRowsCount = rows?.length < MINIMUM_ROWS_AMOUNT ? (MINIMUM_ROWS_AMOUNT - rows.length) : 1;
  const emptyRows = [];

  for (let i = 0; i < emptyRowsCount; i++) {
    emptyRows.push((
      <tr key={`empty-${i}`} className={classNames(classes.tr, 'empty')}>
        {
          columnKeys.map((column, subIndex) => (
            <td key={subIndex} />
          ))
        }
      </tr>
    ));
  }

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          {
            columns.map(({ ...column }, index) => (
              <th
                key={index}
                className={classes.th}
                style={{ width: getColumnWidth(column, mq) }}
              >
                {column.name}
              </th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {
          rows?.map((row, index) => (
            <tr
              key={index}
              className={classes.tr}
            >
              {
                columnKeys.map((column, subIndex) => (
                  <td key={subIndex}>
                    {row[column]}
                  </td>
                ))
              }
            </tr>
          ))
        }
        { emptyRows }
      </tbody>
    </table>
  );
}

export default Table;